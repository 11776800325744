<template>
  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar @menu-toggle="onMenuToggle" />
    <transition name="layout-sidebar">
      <div :class="sidebarClass" @click="onSidebarClick" v-show="isSidebarVisible()">
        <div class="layout-logo">
          <router-link to="/system">
            <img alt="Logo" src="assets/layout/images/user2.png" width="70" />
          </router-link>
        </div>

        <AppProfile />
        <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
      </div>
    </transition>
    <div class="layout-main">
      <router-view />
    </div>
    <AppConfig :layoutMode="layoutMode" :layoutColorMode="layoutColorMode" @layout-change="onLayoutChange"
      @layout-color-change="onLayoutColorChange" />
    <AppFooter />
  </div>
  <!--MODAL ALTERAR SENHA-->
  <Dialog v-model:visible="usuarioAlterarSenhaDialog" header="Alterar Senha" :style="{ width: '400px' }" :modal="true"
    @hide="hideDialog">
    <!--  CADASTRO DE USUARIO -->

    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12">
        <label for="senhaAtual">Senha Atual</label>
        <Password v-model="altSenha.senhaAntiga" :feedback="false" toggleMask="true" autofocus></Password>
      </div>
    </div>
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12">
        <label for="nome">Nova Senha</label>
        <Password v-model="altSenha.novaSenha1" :feedback="true" toggleMask>
          <template #footer="sp">
            {{ sp.level }}
            <br />
            <p class="mt-2">Sugestões:</p>
            <ul class="pl-2 ml-2 mt-0" style="line-height: 1.2">
              <li>Pelo menos uma minúscula.</li>
              <li>Pelo menos uma maiúscula.</li>
              <li>Pelo menos um numérico.</li>
              <li>Mínimo de 8 caracteres.</li>
            </ul>
          </template>
        </Password>
      </div>
    </div>
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12">
        <label for="nome">Confirmar Nova Senha</label>
        <Password v-model="altSenha.novaSenha2" :feedback="false" toggleMask="true"></Password>
      </div>
    </div>

    <template #footer>
      <Button label="Salvar" class="p-button-raised" icon="pi pi-check" @click="updatePassword()" />
      <Button label="Cancelar" icon="pi pi-times" class="p-button-raised p-button-text"
        @click="this.usuarioAlterarSenhaDialog = false" />
    </template>
  </Dialog>
  <!--FIM MODAL MODAL ALTERAR SENHA-->
</template>

<script>
import AppTopBar from "@/components/app/AppTopbar.vue";
import AppProfile from "@/components/app/AppProfile.vue";
import AppMenu from "@/components/app/AppMenu.vue";
//import AppConfig from "@/components/app/AppConfig.vue";
import AppFooter from "@/components/app/AppFooter.vue";

import UsersService from "@/service/Users/UsersService";
import AuthService from "@/service/Auth/AuthService";

export default {
  data() {
    return {
      layoutMode: "static",
      layoutColorMode: "dark",
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      usuarioAlterarSenhaDialog: false,
      token: null,
      altSenha: {
        senhaAntiga: null,
        novaSenha1: null,
        novaSenha2: null,
      },
      menu: [
        { label: "Home", icon: "pi pi-fw pi-home", to: "/system" },
        {
          label: "Datatables",
          icon: "pi pi-fw pi-table ",
          visible: () => (this.permissoes() === "ADMINISTRADOR" || this.permissoes() === "OPERADOR"),
          items: [
            {
              label: "Configurador de DataTable",
              icon: "pi pi-fw pi-cog",
              to: "/config-datatable",
              visible: () => this.permissoes() === "ADMINISTRADOR",
            },

            {
              label: "Gestor de Dados",
              icon: "fa-solid fa-grip-lines",
              to: "/registro-datatable",
              visible: () => (this.permissoes() === "ADMINISTRADOR" || this.permissoes() === "OPERADOR"),
            },
          ],
        },
        {
          label: "Chamamento Público",
          icon: "pi pi-fw pi-book",
          visible: () => (this.permissoes() === "ADMINISTRADOR" || this.permissoes() === "CHAMAMENTO_PÚBLICO" ),
          items: [
            {
              label: "Gerenciamento de Editais",
              icon: "fa-solid fa-grip-lines",
              visible: () => (this.permissoes() === "ADMINISTRADOR" || this.permissoes() === "CHAMAMENTO_PÚBLICO" ),
              to: "/editais",
            },
            {
              label: "Registro de Editais",
              icon: "fa-solid fa-grip-lines",
              visible: () => (this.permissoes() === "ADMINISTRADOR" || this.permissoes() === "CHAMAMENTO_PÚBLICO" ),
              to: "/registros-editais",
            },
          ],
        },
        {
          label: "Links",
          icon: "fa-solid fa-link",
          visible: () => {
            return this.permissoes() === "ADMINISTRADOR"
          },
          items: [
            {
              label: "Gerenciamento de Links",
              icon: "fa-solid fa-grip-lines",
              visible: () => this.permissoes() === "ADMINISTRADOR",
              to: "/links",
            },
          ],
        },
        {
          label: "Setores",
          icon: "fa-solid fa-people-group",
          visible: () => this.permissoes() === "ADMINISTRADOR",
          items: [
            {
              label: "Gerenciamento de Setores",
              icon: "fa-solid fa-grip-lines",
              visible: () => this.permissoes() === "ADMINISTRADOR",
              to: "/setor",
            },
            {
              label: "Gerenciamento de Unidades",
              icon: "fa-solid fa-grip-lines",
              visible: () => this.permissoes() === "ADMINISTRADOR",
              to: "/unidades",
            },
          ],
        },
        {
          label: "Log",
          icon: "pi pi-fw pi-align-right",
          to: "/log",
          visible: () => this.permissoes() === "ADMINISTRADOR",
        },
        {
          label: "Usuários e Grupos",
          icon: "pi pi-fw pi-user",
          items: [
            {
              label: "Gestão de Usuários",
              icon: "pi pi-fw pi-users",
              to: "/usuarios",
              visible: () => this.permissoes() === "ADMINISTRADOR",
            },
            {
              label: "Grupos e Permissões",
              icon: "pi pi-fw pi-clone",
              to: "/gruposAndPermissoes",
              visible: () => this.permissoes() === "ADMINISTRADOR",
            },
            {
              label: "Alterar Senha",
              icon: "pi pi-fw pi-lock",
              command: () => {
                this.abrirAlterarSenha();
              },
            },
          ],
        },
      ],
    };
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    },
  },
  usersService: null,
  authService: null,
  created() {
    this.usersService = new UsersService();
    this.authService = new AuthService();
    this.permissoes();
  },
  methods: {
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === "overlay") {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === "static") {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    onLayoutColorChange(layoutColorMode) {
      this.layoutColorMode = layoutColorMode;
    },
    addClass(element, className) {
      if (element.classList) element.classList.add(className);
      else element.className += " " + className;
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className);
      else
        element.className = element.className.replace(
          new RegExp(
            "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
            "gi"
          ),
          " "
        );
    },
    isDesktop() {
      return window.innerWidth > 1024;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === "static") return !this.staticMenuInactive;
        else if (this.layoutMode === "overlay") return this.overlayMenuActive;
        else return true;
      } else {
        return true;
      }
    },
    updatePassword() {
      let id = this.$Token.jwtDecode(this.token);
      this.usersService
        .updatePassword(id.id, this.altSenha)
        .then((data) => {
          if (data.status === 204) {
            this.usuarioAlterarSenhaDialog = false;
            this.altSenha = {
              senhaAntiga: null,
              novaSenha1: null,
              novaSenha2: null,
            };
            this.$toast.add({
              severity: "success",
              summary: "Alerta!",
              detail: "Senha alterada com sucesso.",
              life: 6000,
            });
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$msgWarn(null);
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Erro!",
              detail: "Erro ao alterar senha do usuário.",
            });
          }
        });
    },
    abrirAlterarSenha(usuario) {
      this.usuario = usuario;
      this.usuarioAlterarSenhaDialog = true;
    },
    permissoes() {
      this.token = sessionStorage.getItem("token");

      //console.log(this.$Token.jwtDecode(this.token));

      return this.$Token.jwtDecode(this.token).grupo;
    },
  },
  computed: {
    containerClass() {
      return [
        "layout-wrapper",
        {
          "layout-overlay": this.layoutMode === "overlay",
          "layout-static": this.layoutMode === "static",
          "layout-static-sidebar-inactive":
            this.staticMenuInactive && this.layoutMode === "static",
          "layout-overlay-sidebar-active":
            this.overlayMenuActive && this.layoutMode === "overlay",
          "layout-mobile-sidebar-active": this.mobileMenuActive,
          "p-input-filled": this.$appState.inputStyle === "filled",
          "p-ripple-disabled": this.$primevue.config.ripple === false,
        },
      ];
    },
    sidebarClass() {
      return [
        "layout-sidebar",
        {
          "layout-sidebar-dark": this.layoutColorMode === "dark",
          "layout-sidebar-light": this.layoutColorMode === "light",
        },
      ];
    },
    logo() {
      return this.layoutColorMode === "dark"
        ? "assets/layout/images/sespa/logoEstado.svg"
        : "assets/layout/images/sespa/logoEstado.svg";
    },
  },
  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, "body-overflow-hidden");
    else this.removeClass(document.body, "body-overflow-hidden");
  },
  components: {
    AppTopBar: AppTopBar,
    AppProfile: AppProfile,
    AppMenu: AppMenu,
    //AppConfig: AppConfig,
    AppFooter: AppFooter,
  },
};
</script>

<style lang="scss">
@import "../App.scss";
</style>
