<template>
  <div class="layout-footer">
    <img src="assets/layout/images/sespa/Brasao.png" alt="SESPA" width="80" />
    <span class="footer-text" style="margin-left: 5px">&copy; CTIS /</span>
    <span class="footer-text" style="margin-right: 5px">
      SESPA - Secretaria de Saúde do Estado do Pará</span
    >
  </div>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>

<style scoped>
</style>