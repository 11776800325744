<template>
  <div class="layout-profile">
    <button class="p-link layout-profile-link p-text-center" @click="onClick">
      <span class="username">
        {{ usuario[0].nome }}. <br />
        Grupo: {{ usuario[0].grupo }}. <br />
        Acesso em {{ formatDataAndTime(usuario[0].generate) }}.
      </span>
      <br />
    </button>
    <transition name="layout-submenu-wrapper">
      <ul v-show="expanded">
        <!--
        <li>
          <button class="p-link" @click="$router.push('/dados-usuario')">
            <i class="pi pi-fw pi-user"></i><span>Minha Conta</span>
          </button>
        </li>
        -->
        <li>
          <button class="p-link" @click="notify">
            <i class="pi pi-fw pi-inbox"></i><span>Notificações</span
            ><span
              class="menuitem-badge"
              style="background: #feedaf; color: #8a5340"
              >0</span
            >
          </button>
        </li>
        <li>
          <button class="p-link" @click="logout">
            <i class="pi pi-fw pi-power-off"></i><span>Sair</span>
          </button>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
import AuthService from "@/service/Auth/AuthService";
import UsersService from "@/service/Users/UsersService";

import Moment from "moment";

export default {
  data() {
    return {
      expanded: true,
      usuario: [],
    };
  },
  authService: null,
  usersService: null,
  created() {
    this.authService = new AuthService();
    if (sessionStorage.getItem("token")) {
      this.$router.push("/system");
      this.configs();
      this.usersService = new UsersService();
    } else {
      this.$router.push("/");
    }
  },
  mounted() {},
  methods: {
    onClick(event) {
      this.expanded = !this.expanded;
      event.preventDefault();
    },
    configs() {
      let token = sessionStorage.getItem("token");
      token = JSON.parse(token);
      token = [
        {
          nome: token.nome,
          grupo: token.grupo,
          id: token.id,
          generate: token.generate,
        },
      ];
      this.usuario = token;
    },
    logout() {
      if (this.$Token.verificarTokenExpirado() === true) {
        this.$Token.limparAccessToken();
        this.$router.push("/login");
      } else {
        this.authService.logout().then((response) => {
          if (response.status == 204) {
            this.$Token.limparAccessToken();
            this.$router.push("/login");
          }
        });
      }
    },
    notify() {
      alert("Not notification.");
    },
    formatDataAndTime(date) {
      if (date) {
        return Moment(date).format("DD/MM/YYYY HH:mm:ss");
      }
      return;
    },
  },
};
</script>

<style lang="scss" scoped>
.evento-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
  &.status-INTERNO {
    background: #633bad;
    color: #fff;
  }
  &.status-EXTERNO {
    background: #138496;
    color: #fff;
  }
  &.status-SOLICITADO {
    background: #feedaf;
    color: #8a5340;
  }
}
.table-header {
  display: flex;
  justify-content: space-between;
}

.p-datatable-sm .p-datatable-tbody > tr > td .p-column-title {
  display: none !important;
}
@media screen and (max-width: 40em) {
  ::v-deep(.p-datatable) {
    &.p-datatable-sm {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;

        .p-column-title {
          padding: 0.4rem;
          min-width: 30%;
          display: inline-block;
          margin: -0.4em 1em -0.4em -0.4rem;
          font-weight: bold;
        }
        &:last-child {
          border-bottom: 1px solid var(--surface-d);
          text-align: right;
        }
        .p-rating {
          display: inline-block;
        }
      }
    }
  }
}
</style>